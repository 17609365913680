import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";

import { ReactComponent as Couponimg } from "../../src/images/couponimg.svg";
import MobileMenu from "../../src/navbarHead/mobileNav";
import '../account/coupon.css';
const MyVoucher = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // Function to fetch order data
    const fetchOrders = async () => {
        const clientId = localStorage.getItem("clientid");
      try {
        const formData = new FormData();
        formData.append("clientid", clientId);
        formData.append("ordervalue", 0);
        // Make the API request to get order data
        const response = await fetch("https://api.starmallonline.com/api/v1/coupon/available", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          const sortedOrders = data.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
          // Set the retrieved order data in state
          setOrders(sortedOrders);
        } else {
          console.error("Failed to get order data");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    // Call the fetchOrders function when the component mounts
    fetchOrders();
  }, []); // Empty dependency array ensures this effect runs once

  const handleViewOrder = (couponid) => {
    localStorage.setItem("selectedOrder", couponid);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(orders.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when the search query changes
  };
  const filteredOrders = orders.filter(order => order.couponcode.includes(searchQuery));

const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
useEffect(() => {
  setSearchQuery("");
}, [currentPage]);
  return (
    <div>
      <Ecomheader />
      <MobileMenu/>
      <div className="container my-5  homebg pt-2">
	<div className="row">
  {currentOrders.map((order) => (
                      
		<div className="col-sm-12 col-md-6 mt-5"  key={order.orderid}>
		    <div className="coupon rounded mb-3 d-flex justify-content-between">
                <div className="p-3">
                    <div className="icon-container ">
                        <div className="icon-container_box">
                        <Couponimg  alt="Logo"    className="couponimg" />
                        </div>
                    </div>
                </div>
                <div className="py-3 d-flex w-100 justify-content-start">
                    <div>
                    
                        <span className="valid">Valid - {new Date(order.validend).toISOString().split('T')[0]}</span>
                        <h3 > {order.couponname}</h3>
                        <p className="text-muted mb-0"><span>{order.coupondescription}</span></p>
                    </div>
                </div>
                <div className="coupon-valid">
                    <div className="info m-3 d-flex align-items-center">
                        <div className="w-100">
                            <div className="coupon-amount d-flex justify-content-center">
                                 
                                <span>value - {order.couponamount}</span>
                                 
                            </div>
                            <span    className="btn btn-sm btn-outline-danger btn-block">
                            {order.couponcode}
                            
                            </span>
                        </div>
                    </div>
                </div>
            </div>
		</div>
     ))}
	</div>
</div>
      
      <Ecomfooter />
    </div>

  )
}

export default MyVoucher;