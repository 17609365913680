import React, { useState, useEffect } from "react";
import {  Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import noimage from '../../src/images/noimage.jpg';
import { addCart, addProduct, editCart } from "../Redux/Redux";
import { useDispatch, useSelector } from "react-redux";
import ProductWrap from "../../src/product/productWrap";
const FeaturedProduct=()=>{
// State to hold the banner images
const [bestImages, setBestImages] = useState([]);
// const [cart, setCart] = useState([]);
const navigate = useNavigate();

const dispatch = useDispatch()
const cart = useSelector(state => state.cart.value)
const wishlist = useSelector(state => state.wishlist.value)

const addToWishList = (productid) => {

  dispatch(addProduct(productid))
};

const addToCart = async (productId) => {
  dispatch(addCart(productId))
  // try {
  //   if(!localStorage.getItem("token")){
  //     navigate('/LoginPage')
  //   }
  //   const response = await fetch(
  //     "https://api.starmallonline.com/api/v1/cart/add",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       body: JSON.stringify({
  //         // clientid: cartid,
  //         clientid:localStorage.getItem("clientid"),
  //         productid: productId,
  //         productquantity: 1,
  //       }),
  //     }
  //   );

  //   if (response.ok) {
  //     addCart(productId);
  //   }
  // } catch (error) {
  //   console.error("Error checking authentication:", error);
  // }
};

// const addCart = (productid) => {
//   // Check if the productid is already in the cart
//   const itemIndex = cart.findIndex((cartItem) => cartItem.productid === productid);

//   if (itemIndex !== -1) {
//     // Product is already in the cart, increase the quantity
//     const updatedCart = [...cart];
//     updatedCart[itemIndex].quantity += 1;

//     setCart(updatedCart);
//     localStorage.setItem('cart', JSON.stringify(updatedCart));
//   } else {
//     // Product is not in the cart, add it as a new entry
//     const updatedCart = [...cart, { productid, quantity: 1 }];
//     setCart(updatedCart);
//     localStorage.setItem('cart', JSON.stringify(updatedCart));
//   }
// };

// Function to fetch banner images
const fetchBestImages = async () => {
  try {
    const payload = {
      currency: localStorage.getItem('currencyName')
    };
    const response = await axios.put("https://api.starmallonline.com/api/v1/product/getfeaturedproduct", payload,{
      
    headers: {
      // 'Authorization': 'Bearer ' +token,
      'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const data = response.data;
      setBestImages(data.slice(0,12)); // Update state with fetched images
    } else {
      console.error('Failed to fetch offer images');
    }
  } catch (error) {
    console.error('Error fetching offer images:', error);
  }
};

// Fetch banner images when the component mounts
useEffect(() => {
    fetchBestImages();
}, []);


  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) + 1 }))
    }
    else {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) - 1 }))
    }
  };


    return(
      bestImages.length > 0 && (
      <section className={bestImages.length == 0 ? 'hidden' : "container"}>    
      <div className="row">
      <div className=" col-12">
            <h2 className="section-title">Flash Selling</h2>
            <a href="/FeaturedProduct" className="section-link">View More</a>
          </div>
      </div>
<div className="row">    
    {bestImages.map((product, index) => (
    // <div key={index} className="col-xs-6 col-sm-2  col-lg-2 col-md-2">
    <ProductWrap key={product.productid} product={product} /> 
    // </div> 
    ))}               
    </div>       
     
</section>  
      )      

    )
}
export default FeaturedProduct;