import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const Megashophome = ({section = 1}) => {
  // State to hold the banner images
  const [bannerImages, setBannerImages] = useState([]);

  // Function to fetch banner images
  const fetchBannerImages = async () => {
    try {
      const response = await fetch("https://api.starmallonline.com/api/v1/banners/get",{
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify({section:section})
      });
      if (response.ok) {
        const data = await response.json();
        setBannerImages(data); // Update state with fetched images
      } else { 
        console.error('Failed to fetch banner images');
      }
    } catch (error) {
      console.error('Error fetching banner images:', error);
    }
  };

  // Fetch banner images when the component mounts
  useEffect(() => {
    fetchBannerImages();
  }, []);

  return (
    <section className={bannerImages.length == 0 ? 'hidden' : section !=1 ? "container-fluied" : "container-fluied"}>
    <Carousel data-bs-theme="dark">
      {bannerImages.map((banner, index) => (
        <Carousel.Item key={index}>
           <Link to={banner.url} className="logo">
            <img src={banner.file}  width="100%" />
          </Link>
          {/* <Link  to="/PageNotFound" className="logo">
            <img src={banner.file}  width="1903" height="530" />
          </Link> */}
         
        </Carousel.Item>
      ))}
    </Carousel>

    </section>
  );
};

export default Megashophome;
