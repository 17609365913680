import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

const ProductCategory = () => {
    const [categories, setCategories] = useState([]);

useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.put("https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory",{
          currency: localStorage.getItem('currencyName')
        });
        if (response.status === 401 || response.status === 404) {
          return;
        }
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return(
    <section className="container">
        
        <ul className="">
        {categories.map((category, index) => (
        <li key={index}>
      <Link to={`/MenuCategory/${category.categoryid}`}>
        {category.categoryname}
      </Link>
      </li>
       ))}
        {/* {categories.map((info, index) => (
            <div className="facility-in">
              <div className="facility-text">
              <h4>{info.categoryname}</h4>
                 
              </div>
                </div>
           
          ))} */}
        </ul>
    </section>
  );
}
export default ProductCategory;