import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileMenu from "../../src/navbarHead/mobileNav";
import {notification, Spin} from 'antd'
const AccountDetails = () => {
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState({});
  const [mobilenumber, setMobilenumber] = useState('');
  const [currentpassword, setcurrentpassword] = useState('');
  const [newpassword, setnewpassword] = useState('');
  const [confirmpassword, setconfirmpassword] = useState('');
  const [mobileotp, setMobileotp] = useState('');
  const [mobileotpverify, setMobileotpVerify] = useState(false);
  const [passwordotpverify, setpasswordotpVerify] = useState(false);
  const [passotp, setpassotp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordChangeResponse, setPasswordChangeResponse] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [seconds]);
  const toggleCurrentPasswordVisibility = () => {
    setCurrentShowPassword(!showCurrentPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewShowPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };
  const [editedClientDetails, setEditedClientDetails] = useState({
    firstname: "",
    lastname: "",
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch("https://api.starmallonline.com/api/v1/login/getclientdetails", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClientDetails(data);
          setMobilenumber(data.mobile || '');
          // Initialize editedClientDetails with clientDetails values
          setEditedClientDetails({
            firstname: data.firstname,
            lastname: data.lastname,
          });
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    fetchClientDetails();
  }, [token]);


  const sendOtptoMobileforpassword = async () => {
    if(seconds!=0){
      return;
    }
    setIsLoading(true);
    if (!newpassword || !confirmpassword) {
      // If OTP is not provided, show a notification
      notification.error({
        message: 'Please Enter Password.',
      });
      setIsLoading(false);
      return;
    }
    setSeconds(30);
    try {
      const response = await fetch("https://api.starmallonline.com/api/v1/client/emailotpclient", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: clientDetails.email,

        }),
      });

      if (response.ok) {
        setpasswordotpVerify(true)
      } else {
        console.error("Failed to send OTP");
        // Handle the error here
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle the error here
    }finally{
      setIsLoading(false);
    }
  };

  const verifyOtptoMobileforpassword = async () => {
    try {
      setIsLoading(true);
      if (!passotp) {
        // If OTP is not provided, show a notification
        notification.error({
          message: 'Error',
          description: 'OTP is required.',
        });
        setIsLoading(false);
        return;
      }
      const response = await fetch("https://api.starmallonline.com/api/v1/client/passwordchange", {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: clientDetails.email,
          otp: passotp.passotp,
          newpassword: newpassword,
        }),
      });
      const data = await response.json();
      if (response.ok && data.status === 'Success') {
        setpasswordotpVerify(true);
        notification.success({
          message: data.description,
          duration: 3, 
        });
        navigate(-1);
        // Handle the error here
      }
      else{
        notification.warning({
          message: data.description,
          duration: 3, 
        })
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle the error here
    }finally{
      setIsLoading(false);
    }
  };
  const handleNewPasswordChange = (e) => {
    const newpassword = e.target.value;
    setnewpassword(newpassword);
    setPasswordMatch(newpassword === confirmpassword);
};
const handleConfirmPasswordChange = (e) => {
  const confirmpasssword = e.target.value;
  setconfirmpassword(confirmpasssword);
  setPasswordMatch(newpassword === confirmpasssword);
};
  return (
    <div>
    <Spin
    fullscreen={true}
    spinning={isLoading}
    />
      <div>
        <Helmet>
          <title>Account</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu />
      <main className="container homebg pt-10">

        <div className="container">

        </div>

        <div className="row  d-flex justify-content-center  align-self-center ">

          <div className="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
            <div className="form-box-header">
              <h2 >Change Password</h2>
            </div>
            <div className="container-fluid">
            <div className="form-group">
                   
                  <label className="otpnotifi mt-5">For Email otp Please check your spam also</label>
                </div>
              <div classname="accountdetails form-group">

                <form action="#" className="">


                    {/* <div className="row ">
                    <label>Current password</label>
                    
                       
                  
                      <div className="col-sm-12 input-group mb-3">
                    <input type={showCurrentPassword ? 'text' : 'password'}
                      className="form-control"
                      name="current_password"
                      onChange={(e) => setcurrentpassword({ currentpassword: e.target.value })} />

                    <div className="input-group-append row">
                      <button
                        type="button"
                        onClick={toggleCurrentPasswordVisibility}
                        className="password-toggle-button"
                        
                      >
                        {showCurrentPassword ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </button>
                    </div>
                    </div>

                    
                    </div> */}
                    <div className="row ">
                  <label>New password </label>

                  <div className="col-sm-12 input-group mb-3">
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      className="form-control"
                      name="new_password"
                      value={newpassword}
                      onChange={handleNewPasswordChange}
                     required
                    />
                    <div className="input-group-append row">
                    <button
                      type="button"
                      onClick={toggleNewPasswordVisibility}
                      className="password-toggle-button"
                      
                    >
                      {showNewPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                  <label>Confirm new password</label>
                  <div className="col-sm-12 col-md-12 input-group mb-3">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      name="confirm_password"
                      value={confirmpassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    <div className="input-group-append row">
                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="password-toggle-button"
                     
                    >
                      {showConfirmPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                  </div>
                  </div>
                  </div>
                  <div className="row">
                  {!passwordMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                  </div>
                  <div className="row ">
                    <div className="col-md-12  d-flex justify-content-center mt-5">
                  {!passwordotpverify ? (
                    <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={sendOtptoMobileforpassword}>
                      Edit Password
                    </button>) : (
                    <div>
                       <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter OTP Here" name="new_password" required onChange={(e) => setpassotp({ passotp: e.target.value })} />
                      </div>
                      <button type="button" className="btn btn-dark btn-md btn-rounded" style={{
                    backgroundColor: seconds==0?'':'red',
                  }} onClick={sendOtptoMobileforpassword}>
                        Resend OTP {seconds==0?'':" IN "+seconds}
                      </button>
                      <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={verifyOtptoMobileforpassword}>
                        verify OTP
                      </button>
                    </div>
                  )}
                  {/* {passwordChangeResponse && (
                    <div className="response-message">{passwordChangeResponse}</div>
                  )} */}
                </div>
                </div>
                </form>


              </div>

            </div>

          </div>
        </div>

       








      </main>
      <Ecomfooter />
    </div>
  )
}

export default AccountDetails;