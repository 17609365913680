import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import axios from "axios";
import { Form, notification, Spin } from "antd";
import { Helmet } from "react-helmet";
import {
  faEye,
  faEyeSlash,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useDispatch} from 'react-redux'
import { transferCart, transferWishList } from "../Redux/Redux";
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";

const Reset = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otpResponseMessage, setOtpResponseMessage] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPasswrd] = useState(""); 
  const [confirmpasssword, setConfirmpasssword] = useState(""); // Add state for email input
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [submiOtpResponse, setSubmiOtpResponse] = useState("");
  const [otpseconds, setotpseconds] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const checkMobileAvailability = async (mobile) => {
   
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await axios.put(
        "https://api.starmallonline.com/api/v1/login/checkavailableemail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
        const msg = response.data;
        return msg.message == "" ? false : true;
      
    } catch (error) {
      console.error("Error checking mobile availability:", error);
      return false;
    }
  };
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (otpseconds > 0) {
        setotpseconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [otpseconds]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const sendOtpforForgot = async () => {
    try {
      
      const isMobileAvailable = await checkMobileAvailability(email);
      
      if (isMobileAvailable == true) {
      // Check if email is empty or invalid
      if (!email || !validateEmail(email)) {
        notification.warning({
          message: "Please Enter a Valid Email Address",
          duration: 2,
        });
        return;
      }

      const requestData = {
        email: email,
      };
      const response = await axios.put(
        "https://api.starmallonline.com/api/v1/client/emailotpclient",
        requestData
      );

      if (response.status === 200) {
        setOtpResponseMessage(
          "OTP sent successfully. Check your email for the OTP."
        );
        setShowOtpField(true);
        
        // Send notification for OTP sent successfully
        notification.success({
          message: "OTP Sent Successfully",
          duration: 2,
        });
        setotpseconds(30)
      } else {
        setOtpResponseMessage("Failed to send OTP. Please try again.");
      }
      
    }else{
      setOtpResponseMessage(<Link to='/RegisterPopup'>click here to register</Link>);
      notification.warning({
        message: "user not registered",
        duration: 2,
      });
    }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setOtpResponseMessage(
        "An error occurred while sending OTP. Please try again later."
      );
    }
  };

  
  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setPasswrd(password);
    setPasswordsMatch(password === confirmpasssword);
};
const handleConfirmPasswordChange = (e) => {
    const confirmpasssword = e.target.value;
    setConfirmpasssword(confirmpasssword);
    setPasswordsMatch(password === confirmpasssword);
};
const handleVerifyOtp = async () => {
    try {
      const response = await axios.put("https://api.starmallonline.com/api/v1/client/passwordchange", {
        otp,
        mobile:email ,
        newpassword:password,
      });
  
      // Assuming your API returns a token on success
      if (response.data.status === "Success") {
        setSubmiOtpResponse("Success: " + response.data.description);
        // Navigate to LoginPage
        navigate("/LoginPage");
      }
      else{
        
        setSubmiOtpResponse(
            "Failed: " + response.data.description + " " + "Resend the OTP"
          );
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  
  const  handleVerifyOtpforResend = async () => {
    if(otpseconds!=0){
      return;
    }
    setOtp("");
    setConfirmpasssword("");
    setPasswrd("");
    try {
        if(!email){
            notification.warning({
                message:"Please Enter Your Registered Email",
                duration:2,
            });
            return;
        }
      // Create a request payload with the email address
      const requestData = {
        email: email,
      };

      // Make an HTTP PUT request to the emailotpclient API
      const response = await axios.put(
        'https://api.starmallonline.com/api/v1/client/emailotpclient',
        requestData
      );

      if (response.status === 200) {
        setOtpResponseMessage('OTP sent successfully. Check your email for the OTP.');
        notification.warning({
            message:'OTP sent successfully. Check your email for the OTP.',
            duration:2,
        });
        setShowOtpField(true);
        setotpseconds(30)
      } else {
        // Handle other response statuses if needed
            setOtpResponseMessage('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error('Error sending OTP:', error);
      setOtpResponseMessage('An error occurred while sending OTP. Please try again later.');
    }
  };
  useEffect(() => {
    const getClientDetailsApi = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://api.starmallonline.com/api/v1/login/getclientdetails", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client details");
        }
        
        const clientData = await response.json(); // Parse response body as JSON
        if (clientData && clientData.id) {
          navigate("/Homepage");
        }else{
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
        // Handle error: e.g., show a message to the user
      }
    };
  
    getClientDetailsApi();
  }, [token]);
  return (
    <>
    
    <Spin
    fullscreen={true}
    spinning={isLoading}
    />
    {loading && 
    <div>
      <div>
        <Helmet>
          <title>Reset</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu/>
      <div className=" ">
       <div className="container homebg pt-5">

            <div className="row  d-flex justify-content-center  align-self-center ">
            <div className="col-lg-5 col-md-5 col-sm-12 form-box-for-border">
            <div className="form-box-header">
            <h2>Reset password</h2>
            </div>
                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-sm-12 input-group mb-3">
                      <input
                        type="text" 
                        className="form-control"
                        id="mobile-number"
                        name="mobile-number"
                        placeholder="Enter Your Email *"
                        value={email}
                        onChange={(e) =>{
                          const inputValue = e.target.value.replace(/\s/g, '');
                           setEmail(inputValue)
                          }}
                        required
                      />
                      <div className="input-group-append row">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <label className="otpnotifi">For Email otp Please check your spam also</label>
                  {!otpResponseMessage &&
                <div className="row">
                    <div className="col-sm-12"> 
                <button
                  className="btn btn-dark btn-block btn-rounded"
                  onClick={sendOtpforForgot}
                  style={{
                    // display: showOtpFieldforlogin ? "block" : "none",
                  }}
                >
                  NEXT
                </button>
                </div>
                </div>}
                
                {otpResponseMessage && <div className="response-message">{otpResponseMessage}</div>}
                </div>
                {showOtpField && (
                    <>
                <div className="row">
                  <div className="col-sm-12 input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                      <div className="col-sm-12 input-group mb-3">
                        <input
                          type="password"
                          name="otp"
                          className="form-control"
                          placeholder="Enter Password"
                          value={password}
                          onChange={handleNewPasswordChange}
                        />
                      </div>
                      <div className="col-sm-12 input-group mb-3">
                        <input
                          type="password"
                          name="otp"
                          className="form-control"
                          placeholder="Enter ConfirmPassword"
                          value={confirmpasssword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                      {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                </div>
                 <div className="row">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-dark btn-block btn-rounded"
                          onClick={handleVerifyOtp}
                          style={{ width: "100%" }}
                        >
                          Change Password
                        </button>
                      </div>
                      </div>
                      {submiOtpResponse && (
                  <div className="response-message">{submiOtpResponse}</div>
                )}
                 <div className="row">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-dark btn-block btn-rounded"
                          onClick={handleVerifyOtpforResend}
                          style={{ width: "100%" , backgroundColor: otpseconds!=0? 'red':''}}
                        >
                          Resend OTP {otpseconds==0?'':" IN "+otpseconds}
                        </button>
                      </div>
                      </div>
                 </>  
                 
              )}
              
            </div>
            </div>
       </div>
      <Ecomfooter />
    </div>
    </div>
    }
    </>
  );
};
export default Reset;
