import React, { useState, useEffect } from 'react';
const Reviews = () => {
  const token = localStorage.getItem("token");
  const [reviews, setReviews] = useState([]);
  const productid = localStorage.getItem('productid');
  const [clientDetails, setClientDetails] = useState({});
  const clientid = localStorage.getItem('clientid');
  useEffect(() => {
    // Fetch Client Details
    const clientDetailsUrl = 'https://api.starmallonline.com/api/v1/login/getclientdetails';
    const clientDetailsRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Use the token for authorization
      },
    };

    const fetchClientDetails = async () => {
      try {
        const response = await fetch(clientDetailsUrl, clientDetailsRequestOptions);
        const data = await response.json();
        setClientDetails(data);
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    // Define the API endpoint
    const apiUrl = 'https://api.starmallonline.com/api/v1/review/getreviews';

    // Define the request payload
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ productid: productid }),
    };

    // Make the API request
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data); // Update the state with the received data
      })
      .catch((error) => {
        console.error('Error fetching reviews:', error);
      });
  }, [productid]);
  useEffect(() => {
    // Define the API endpoint
    const apiUrl = 'https://api.starmallonline.com/api/v1/review/getreviewsforclient';

    // Define the request payload
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ productid, clientid: clientDetails.id }), // Include both productid and clientid in the payload
    };

    // Make the API request
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data); // Update the state with the received data
      })
      .catch((error) => {
        console.error('Error fetching reviews for client:', error);
      });
  }, [productid, clientDetails]);
  return (
    <div className="">
      <div className="" id="product-tab-reviews">
        <div className="row">
          <div className="col-lg-4 mb-6">
            <div className="avg-rating-container">
              <mark>5.0</mark>
              <div className="avg-rating">
                <span className="avg-rating-title">Average Rating</span>
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "100%" }}></span>
                    <span className="tooltiptext tooltip-top"></span>
                  </div>
                  <span className="rating-reviews">(2 Reviews)</span>
                </div>
              </div>
            </div>
            <div className="ratings-list mb-2">
              <div className="ratings-item">
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "100%" }}></span>
                    <span className="tooltiptext tooltip-top"></span>
                  </div>
                </div>
                <div className="rating-percent">
                  <span style={{ width: "100%" }}></span>
                </div>
                <div className="progress-value">100%</div>
              </div>
              <div className="ratings-item">
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "80%" }}></span>
                    <span className="tooltiptext tooltip-top">4.00</span>
                  </div>
                </div>
                <div className="rating-percent">
                  <span style={{ width: "0%" }}></span>
                </div>
                <div className="progress-value">0%</div>
              </div>
              <div className="ratings-item">
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "60%" }}></span>
                    <span className="tooltiptext tooltip-top">4.00</span>
                  </div>
                </div>
                <div className="rating-percent">
                  <span style={{ width: "0%" }}></span>
                </div>
                <div className="progress-value">0%</div>
              </div>
              <div className="ratings-item">
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "40%" }}></span>
                    <span className="tooltiptext tooltip-top">4.00</span>
                  </div>
                </div>
                <div className="rating-percent">
                  <span style={{ width: "0%" }}></span>
                </div>
                <div className="progress-value">0%</div>
              </div>
              <div className="ratings-item">
                <div className="ratings-container mb-0">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: "20%" }}></span>
                    <span className="tooltiptext tooltip-top">4.00</span>
                  </div>
                </div>
                <div className="rating-percent">
                  <span style={{ width: "0%" }}></span>
                </div>
                <div className="progress-value">0%</div>
              </div>
            </div>
            <a className="btn btn-dark btn-rounded submit-review-toggle" href="#">Submit
              Review</a>
          </div>
          <div className="col-lg-8 comments pt-2 pb-10 border-no">
            <nav className="toolbox">
              <div className="toolbox-left">
                <div className="toolbox-item">
                  <a href="#" className="btn btn-outline btn-rounded">All Reviews</a>
                </div>
                <div className="toolbox-item">
                  <a href="#" className="btn btn-outline btn-rounded">With Images</a>
                </div>
                <div className="toolbox-item">
                  <a href="#" className="btn btn-outline btn-rounded">With Videos</a>
                </div>
              </div>
              <div className="toolbox-right">
                <div className="toolbox-item toolbox-sort select-box text-dark">
                  <label>Sort By :</label>
                  <select name="orderby" className="form-control">
                    <option value>Default Order</option>
                    <option value="newest" selected="selected">Newest Reviews
                    </option>
                    <option value="oldest">Oldest Reviews</option>
                    <option value="high_rate">Highest Rating</option>
                    <option value="low_rate">Lowest Rating</option>
                    <option value="most_likely">Most Likely</option>
                    <option value="most_unlikely">Most Unlikely</option>
                  </select>
                </div>
              </div>
            </nav>
            <ul className="comments-list">
              <li>
                <div className="comment">
                  <figure className="comment-media">
                    <a href="#">
                      <img src="images/blog/comments/1.jpg" alt="avatar" />
                    </a>
                  </figure>
                  <div className="comment-body">
                    <div className="comment-rating ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: "100%" }}></span>
                        <span className="tooltiptext tooltip-top"></span>
                      </div>
                    </div>
                    <div className="comment-user">
                      <span className="comment-date">by <span className="font-weight-semi-bold text-uppercase text-dark">John
                        Doe</span> on
                        <span className="font-weight-semi-bold text-dark">Nov 22,
                          2018</span></span>
                    </div>
                    <div className="comment-content mb-5">
                      <p>Sed pretium, ligula sollicitudin laoreet viverra, tortor
                        libero sodales leo,
                        eget blandit nunc tortor eu nibh. Nullam mollis. Ut
                        justo.
                        Suspendisse potenti.
                        Sed egestas, ante et vulputate volutpat, eros pede
                        semper
                        est, vitae luctus metus libero eu augue.</p>
                    </div>
                    <div className="file-input-wrappers">
                      <img className="btn-play btn-img pwsp" src="images/products/product1.jpg" width="280" height="315" alt="product" />
                      <img className="btn-play btn-img pwsp" src="images/products/product3.jpg" width="280" height="315" alt="product" />
                      {/* <a className="btn-play btn-iframe" style={{background-image: "url(images/product/product.jpg)",background-size: "cover" href="video/memory-of-a-woman.mp4"/> */}
                      <i className="d-icon-play-solid"></i>
                      {/* </a> */}
                    </div>
                    <div className="feeling mt-5">
                      <button className="btn btn-link btn-icon-left btn-slide-up btn-infinite like mr-2">
                        <i className="fa fa-thumbs-up"></i>
                        Like (<span className="count">0</span>)
                      </button>
                      <button className="btn btn-link btn-icon-left btn-slide-down btn-infinite unlike">
                        <i className="fa fa-thumbs-down"></i>
                        Unlike (<span className="count">0</span>)
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="comment">
                  <figure className="comment-media">
                    <a href="#">
                      <img src="images/blog/comments/2.jpg" alt="avatar" />
                    </a>
                  </figure>
                  <div className="comment-body">
                    <div className="comment-rating ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: "100%" }}></span>
                        <span className="tooltiptext tooltip-top"></span>
                      </div>
                    </div>
                    <div className="comment-user">
                      <span className="comment-date">by <span className="font-weight-semi-bold text-uppercase text-dark">John
                        Doe</span> on
                        <span className="font-weight-semi-bold text-dark">Nov 22,
                          2018</span></span>
                    </div>
                    <div className="comment-content">
                      <p>Sed pretium, ligula sollicitudin laoreet viverra, tortor
                        libero sodales leo, eget blandit nunc tortor eu nibh.
                        Nullam
                        mollis.
                        Ut justo. Suspendisse potenti. Sed egestas, ante et
                        vulputate volutpat,
                        eros pede semper est, vitae luctus metus libero eu
                        augue.
                        Morbi purus libero,
                        faucibus adipiscing, commodo quis, avida id, est. Sed
                        lectus. Praesent elementum
                        hendrerit tortor. Sed semper lorem at felis. Vestibulum
                        volutpat.</p>
                    </div>
                    <div className="feeling mt-5">
                      <button className="btn btn-link btn-icon-left btn-slide-up btn-infinite like mr-2">
                        <i className="fa fa-thumbs-up"></i>
                        Like (<span className="count">0</span>)
                      </button>
                      <button className="btn btn-link btn-icon-left btn-slide-down btn-infinite unlike">
                        <i className="fa fa-thumbs-down"></i>
                        Unlike (<span className="count">0</span>)
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <nav className="toolbox toolbox-pagination justify-content-end">
              <ul className="pagination">
                <li className="page-item disabled">
                  <a className="page-link page-link-prev" href="#" aria-label="Previous" tabindex="-1" aria-disabled="true">
                    <i className="d-icon-arrow-left"></i>Prev
                  </a>
                </li>
                <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a>
                </li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item page-item-dots"><a className="page-link" href="#">6</a>
                </li>
                <li className="page-item">
                  <a className="page-link page-link-next" href="#" aria-label="Next">
                    Next<i className="d-icon-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="review-form-section">
          <div className="review-overlay"></div>
          <div className="review-form-wrapper">
            <div className="title-wrapper text-left">
              <h3 className="title title-simple text-left text-normal">Add a Review</h3>
              <p>Your email address will not be published. Required fields are marked *
              </p>
            </div>
            <div className="rating-form">
              <label htmlFor="rating" className="text-dark">Your rating * </label>
              <span className="rating-stars selected">
                <a className="star-1" href="#">1</a>
                <a className="star-2" href="#">2</a>
                <a className="star-3" href="#">3</a>
                <a className="star-4 active" href="#">4</a>
                <a className="star-5" href="#">5</a>
              </span>
              <select name="rating" id="rating" required style={{ display: "none" }}>
                <option value>Rate…</option>
                <option value="5">Perfect</option>
                <option value="4">Good</option>
                <option value="3">Average</option>
                <option value="2">Not that bad</option>
                <option value="1">Very poor</option>
              </select>
            </div>
            <form action="#">
              <textarea id="reply-message" cols="30" rows="6" className="form-control mb-4" placeholder="Comment *" required></textarea>
              <div className="review-medias">
                <div className="file-input form-control image-input">
                  <div className="file-input-wrapper">
                  </div>
                  <label className="btn-action btn-upload" title="Upload Media">
                    <input type="file" accept=".png, .jpg, .jpeg" name="riode_comment_medias_image_1" />
                  </label>
                  <label className="btn-action btn-remove" title="Remove Media">
                  </label>
                </div>
                <div className="file-input form-control image-input">
                  <div className="file-input-wrapper">
                  </div>
                  <label className=" btn-action btn-upload" title="Upload Media">
                    <input type="file" accept=".png, .jpg, .jpeg" name="riode_comment_medias_image_2" />
                  </label>
                  <label className="btn-action btn-remove" title="Remove Media">
                  </label>
                </div>
                <div className="file-input form-control video-input">
                  <video className="file-input-wrapper" controls></video>
                  <label className="btn-action btn-upload" title="Upload Media">
                    <input type="file" accept=".avi, .mp4" name="riode_comment_medias_video_1" />
                  </label>
                  <label className="btn-action btn-remove" title="Remove Media">
                  </label>
                </div>
              </div>
              <p>Upload images and videos. Maximum count: 3, size: 2MB</p>
              <button type="submit" className="btn btn-primary btn-rounded">Submit<i className="d-icon-arrow-right"></i></button>
            </form>
          </div>
        </div>

      </div>
    </div>

  );
}
export default Reviews;